<script setup lang="ts">
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { SplitSectionRecord } from "~/graphql/datocms/generated";

const props = defineProps<{ data: SplitSectionRecord }>();

const localePath = useLocalePath();

const itemList = computed(() => props.data.items.map((i) => {
  return {
    id: i.id,
    text: i.text,
    linkItem: getLinkForType(i.optLinkItem, localePath),
    image: i.image,
  };
}));
</script>

<template>
  <div class="grid grid-cols-1 gap-lg md:grid-cols-2 md:gap-3xl">
    <div v-for="item in itemList" :key="item.id" class="flex flex-col gap-xl bg-[#F3F1EC] p-md md:p-xl">
      <div class="html-content">
        <div v-html="item.text" />
        <p v-if="item.linkItem.to">
          <NuxtLinkLocale :to="item.linkItem.to" :target="item.linkItem.target" class="text-primary underline">
            {{ item.linkItem.label }}
          </NuxtLinkLocale>
        </p>
      </div>

      <DatocmsImage v-if="item.image" :data="item.image.responsiveImage as ResponsiveImageType" object-fit="cover" class="rounded-sm" />
    </div>
  </div>
</template>

<style scoped>

</style>
